.dropEditor {
  position: relative;
  height: 32px;
  border: 1px solid #40a9ff;
  border-radius: 4px;
  margin: 10px 10px 0 0;
  display: flex;
  align-items: center;
  padding-left: 5px;
}

.dropEditor:hover .close {
  display: flex;
}

.close {
  position: absolute;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  right: -9px;
  top: -9px;
  display: none;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 1px solid red;
  color: red;
  background-color: #fff;

}

.hide {
  display: none !important;
}

.editorContent {
  display: flex;
  align-items: center;
  white-space: nowrap;
  width: 100%;
}

.overInput {
  position: absolute;
  top: 5px;
  left: 5px;
}

.actionContainer {
  display: flex;
  align-items: center;
  justify-content: right;
  margin-top: 5px;

  .action {
    cursor: pointer;
  }
}

.titleActions {
  display: flex;

  .action {
    width: 18px;
    height: 18px;
    font-size: 18px;
    cursor: pointer;
    margin-left: 5px;
    display: none;
  }
}

.editColTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:hover .action{
     display: block;
  }
}

.svgDND_editor {
  display: flex;
}

.svgPreview{
  width: 100px;
  height: 100px;
  margin-left: 20px;
}

.svgPreview img {
  width: 100%;
  height: 100%;
}

@primary-color: #1DA57A;