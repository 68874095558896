.BorderSelect {
  :global {
    .ant-select-selection-selected-value {
      width: 100%;
      height: 30px;
      display: flex !important;
      align-items: center;
    }
  }
}

.BorderSelect_option {
  width: 100%;
  height: 10px;
}

@primary-color: #1DA57A;