.TopRow {
  display: flex;
  padding-left: 5px;
  padding-right: 5px;
  background: #e5e7e8;
  justify-content: space-between;
}

.TopRow > span {
  white-space: nowrap;
}

.TopRow > span > div {
  font-size: .8em;
  vertical-align: unset;
  display: inline;
}

.TopRowIcon {
  display: inline;
  float: left;
}

.buttonsContainer {
  margin-left: 100px;
}

.version {
  display: flex;
}

.inputWidth {
  width: 250px;
}

@primary-color: #1DA57A;