.odata-tree-container .ant-tree-title {
  color: #1890ff;
}

.odata-tree-container .drag-over .ant-tree-title {
  color: #fff;
}

.ant-tree-list-holder-inner > .ant-tree-treenode:nth-child(even) {
  background-color: #fff;
}

.ant-tree-list-holder-inner > .ant-tree-treenode:nth-child(odd) {
  background-color: #E8E8E8;
}

.version-thread-menu {
  z-index: 9999999;
}

.version-thread-menu .ant-dropdown-menu {
  max-height: 120px;
  overflow-y: auto;
}

.home-search-complete .ant-select-selection-search-input {
  padding-right: 18px !important;
}

.ql-container.ql-snow {
  overflow: auto;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input[type="number"]{
  -moz-appearance: textfield;
}

.report-table .ant-table-cell {
  padding: 4px !important;
}

.report-table .ant-table-title {
  padding: 5px 10px;
}

.custom-submenu-title .ant-menu-submenu-title {
  display: flex;
  align-items: center;
}


.orbit-editor .editor-body .editor-panel{
  width: 100%;
}
