.ColorInput {
  &_container {
    position: relative;
  }
  
  &_input {
    width: 200px;
    height: 32px;
    display: flex;
  }

  &_button {
    border: 1px solid #ddd;
    padding: 4px;
    height: 100%;
    border-radius: 4px;
    flex: 1;
    cursor: pointer;
  }

  &_buttonInner {
    position: relative;
    height: 100%;
    border-radius: 4px;
    background-color: #fff;
    overflow: hidden;
    width: 166px;
  }

  &_buttonInnerEmpty {
    &::before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      border: 1px dashed #ddd;
      background-image: linear-gradient(
        to top right,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) calc(50% - 1px),
        red 50%,
        rgba(0, 0, 0, 0) calc(50% + 1px),
        rgba(0, 0, 0, 0) 100%
      );
      background-repeat: no-repeat;
    }
  }

  &_clearInput {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.25);
    position: absolute;
    right: 4px;
    top: 6px;
    border: 0;
    outline: 0;
    background-color: transparent;
    z-index: 100;
    cursor: pointer;

    &:hover {
      color: rgba(0, 0, 0, 0.45);
    }
  }

  &_picker {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 10;
    display: none;
  }

  &_openedPicker {
    display: block;
  }
}
@primary-color: #1DA57A;