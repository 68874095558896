.bottomPane {
  display: flex;
  align-items: center;
  background-color: #2f477f;
  color: #cdd3df;
  height: 36px;
  padding: 0 10px;

  &_sep {
    flex: 1;
  }

  &_link {
    color: rgb(205, 211, 223);
    cursor: pointer;
  }

  &_plink {
    color: rgb(205, 211, 223);
    cursor: pointer;
    margin: 0 5px;
  }
}

.tableHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.searchContainer {
  width: 120px;
}

.dndContainer {
  cursor: pointer;
}

@primary-color: #1DA57A;