@import '../../styles/variableds';

.loginAction {
  display: inline;
}

.loginAction .loginText {
  color: @topPaneTextColor;
}

.bottomLinks {
  text-align: center;
}

@primary-color: #1DA57A;