.horizontal-form {
  .form-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 10px;
  }

  .form-item-label {
    text-align: right;
    padding-right: 5px;
  }

  .buttons-container {
    margin-top: 30px;
  }
}
