@border: 1px solid #d4d4d4;

.formula-editor {

  .formula-header {
    display: flex;
    border: @border;

    .operator-container {
      width: 70%;
      display: flex;
    }

    .variables-container {
      width: 30%;
      display: flex;
      border-right: none;
    }
  }

  .operator-container, .variables-container {
    border-right: @border;
    padding: 5px 10px;
    flex-wrap: wrap;

    .symbol {
      margin-bottom: 5px;
    }
  }

  .editor {
    margin-top: 20px;
    height: 40px;
    outline: none;
    padding: 5px;
    border: @border;
    padding: 5px 10px;
  }

  .preview-container {
    margin-top: 20px;
    border: @border;
    padding: 5px 10px;
  }

  .preview {
    height: 140px;
  }

  .symbol {
    height: 30px;
    margin-left: 10px;
    display: flex;
    align-items: center;

    .symbol-button-inner {
      display: block;
      height: 28px;
      width: 28px;
    }

    svg {
      height: 28px;
      width: 28px;
    }
  }
}

@primary-color: #1DA57A;