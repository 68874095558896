.viewFormTitle {
  display: flex;
  align-items: center;
  white-space: nowrap;

  .title {
    flex: auto;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.viewFormTitle img {
  width: 20px;
  height: 20px;
}

.iconContainer {
  width: 24px;
  height: 24px;
  background-color: #eee;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

@primary-color: #1DA57A;