@import 'variableds';

.app-side-menu {
  background-color: @sidebarBgColor;

  .ant-menu.ant-menu-dark {
    background-color: @sidebarSubmenuBgColor;
  }

  .ant-menu-dark .ant-menu-inline.ant-menu-sub {
    background-color: @sidebarBgColor;
  }
}

@primary-color: #1DA57A;