.viewForm {
  flex: 1;
  overflow: auto;
}

.formCategoryWrapper {
  margin-bottom: 20px;
}

.formSubHeader {
  font-size: 18px;
  font-weight: bold;
  color: #999;
  margin-bottom: 8px;
}

.formRow {
  margin-bottom: 10px;
  display: flex;
  align-items: center;

  .formLabel {
    margin-right: 8px;
    width: 140px;
  }

  .formControl {
    border: 1px solid #ccc;
    width: 200px;
  }

  .formControl_colorPicker {
    border: initial;
    width: 200px;
  }
}

.ActiveRow {
  background-color: rgba(135, 206, 235, 0.15);
}

.RowContextOverlay {
  z-index: 1000000000;
  min-width: 150px !important;
}
@primary-color: #1DA57A;