.builderContainer {
  border: 1px solid #e8e8e8;
  padding: 4px;
}

.header {
  margin-left: 4px;
  text-align: left;
}

.builderRow {
  display: flex;
}

.builderCell {
  border: 1px solid #e8e8e8;
  width: 20px;
  height: 20px;
  margin-left: 2px;
  margin-top: 2px;
  cursor: pointer;
}

.active {
  border-color: orange;
}

@primary-color: #1DA57A;