.viewForm {
  flex: 1;
  overflow: auto;
}

.formCategoryWrapper {
  margin-bottom: 20px;
}

.formSubHeader {
  font-size: 18px;
  font-weight: bold;
  color: #999;
  margin-bottom: 8px;
}

.formRow {
  margin-bottom: 10px;
  display: flex;
  align-items: center;

  .formLabel {
    margin-right: 8px;
    width: 140px;
  }
}

@primary-color: #1DA57A;