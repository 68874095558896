.ViewFormPreview_tableRow {
  user-select: none;
}

.ViewFormPreview_tableRow_active {
  background-color: rgba(135, 206, 235, 0.15);
}

.ViewFormPreview_tableCell {
  user-select: none;
}

.ViewFormPreview_tableCell_active {
  background-color: rgba(135, 206, 235, 0.15);
}
@primary-color: #1DA57A;