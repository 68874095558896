@import '../../../styles/variableds';

.appSideMenu {
  position: relative;
  height: 100%;
  width: 250px;
  max-width: 3000px;
  transform: translate3d(0, 0, 0);
  transition: all .5s cubic-bezier(.25,.1,.36,1);
  user-select: none;

  &Hide {
    transform: translate3d(-100%, 0, 0);
    max-width: 0;
  }

  &_body {
    height: 100%;
    overflow-y: auto;
  }

  &_resizer {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 5px;
    background-color: #ccc;
    cursor: ew-resize;
  }

  &_resizerFake {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 5px;
    background-color: #ccc;
  }

  &_pin {
    position: absolute;
    right: -25px;
    top: 20px;
    width: 24px;
    height: 24px;
    background-color: rgba(235, 235, 235, 1);
    border: 1px solid #ccc;
    cursor: pointer;
  }

  &_pinIcon {
    width: 24px;
    height: 24px;
    transform: rotate(0);
    transition: transform .3s linear;

    &Shown {
      transform: rotate(180deg);
    }
  }

  &_base_content {
    padding: 0 10px 0 5px;
  }

  &_listItem {
    padding: 6px 20px;
    cursor: pointer;
    background-color: #fff;

    &_inner {
      width: 100%;
      height: 100%;
    }

    &:hover {
      background-color: #ddd;

      .appSideMenu_viewItem_inner_close {
        visibility: visible;
      }
    }
  }

  &_viewItem_inner {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;

    &_text {
      flex: 1;
      width: 0;

      &_visible {
        color: skyblue;
      }
    }

    &_close {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20px;
      height: 20px;
      visibility: hidden;
    }
  }
}

.windowsContainer {
  background-color: #fff;
}

.accordionChild {
  background-color: rgba(47,71,127, 0.95) !important;
}

.indent {
  margin-left: 10px;
}

.windowItem {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:hover {
    .appSideMenu_viewItem_inner_close {
      visibility: visible;
    }
  }

  .windowName {
    max-width: calc(100% - 26px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    & > div {
      width: 100%;
    }
  }
}

.rightActions {
  position: absolute;
  top: 0;
  right: 6px;
  display: flex;
  align-items: center;
}

.rightActions .submenuArrow {
  position: static !important;
  display: block;
  margin-top: 2px;
}

:global {
  .app-side-menu {
    .appSideMenu_submenu-root.ant-menu-submenu {
      .ant-menu-submenu-title,
      .ant-menu-item {
        margin: 0;
      }

      &.ant-menu-submenu-open {
        background-color: @sidebarPrimaryColor;

        > .ant-menu-submenu-title {
          position: relative;

          &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            width: 2px;
            background: #fff;
          }
        }
      }
    }
  }
}

@primary-color: #1DA57A;