.Mainpane {
    width: 100%;
    overflow-x: auto;
}

.TopRow {
    display: flex;
    padding-left: 5px;
    padding-right: 5px;
    background: #e5e7e8;
}

.TopRow > span {
    white-space: nowrap;
}

.TopRow > span > div {
    font-size: .8em;
    vertical-align: unset;
    display: inline;
}

.TopRowIcon {
    display: inline;
    float: left;
}

.TopRowDsc {
    display: inline;
    float: left;
    white-space: nowrap;
}

.TopRowRightContainer {
  height: auto;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.TopRowRightContainer > div {
  padding-left: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.TopRowRightContainer > div > svg, .TopRowRightContainer > div > span > svg {
  width: 18px;
  height: 18px;
}

.viewFormViews:hover {
  color: #1890ff;
}

.iconWrapper {
  cursor: pointer;
}

.iconWrapper:hover {
  color: #1890ff;
}

.tableDesignIcon {
  cursor: pointer;
  width: 16px;
  height: 16px;
  fill: rgba(0, 0, 0, 0.85);
  padding-left: 4px;
}

.tableDesignIcon:hover {
    fill: #1890ff;
}

.TopRowVersion > span > svg {
    position: relative;
    bottom: -.125em;
}

.TopRowVersion > span:hover {
    color: #1890ff;
}

.activeIcon {
  color: #1890ff;
}

.TopRowVersion > span > div {
    font-size: .6em;
    display: inline;
    vertical-align: sub;
}

.RowActionAbsolutePositioner {
    color: rgba(0, 0, 0, 0.65);
    position: absolute;
    background: white;
    right: 1px;
    top: 1px;
}

.CellActionIconsContainer {
    white-space:nowrap;
    color: rgba(0, 0, 0, 0.65);
    float: right;
    position: relative;
    background: white;
    cursor: default;
}

.CellActionAbsolutePositioner {
    position: relative;
    float: right;
    width: 0px;
}

.CellActionIconsContainer > svg {
    margin-left: .25em;
}

.CellActionIconsContainer > svg:last-of-type {
    margin-right: .25em;
}

.ViewFormTable {
    table-layout: auto;
    height: 1px; // fix to make the 100% cell height work
    &_TR {
        height: 100%;

        &_RowDesignMode {
            transform: scale(1);
        }

        &_TD {
            padding: 0px;
            height: 100%;
            &_Div {
                padding: 0px;
                height: 100%;
                width: 100%;
            }
        }
    }
    &_ContentDiv {
        padding: 10px;
    }
}

.ViewFormTableView {
}

.vfIconAndTitle {
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.vfIconAndTitle > img {
  width: 20px;
  height: 20px;
}

.deleteIcon {
  width: 18px;
  height: 18px;
  cursor: pointer;
}

.deleteIcon:hover {
  fill: red;
}

.tableCell {
  position: relative;
}

.tableCell:hover .editAction{
  display: block;
}

.editAction {
  position: absolute;
  top: 0;
  right: 5px;
  display: none;
}

@primary-color: #1DA57A;