.dragUpload {
  height: 120px !important;
}

.fileName {
  text-align: center;
  height: 30px;
  margin-top: 10px;
}

.preview img {
  width: 80px;
  height: 80px;
  margin-top: 10px;
}

@primary-color: #1DA57A;