.viewForm_container {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.viewForm_preview {
  width: 100%;
  overflow: auto;
  margin-bottom: 20px;
}

.dragHandle {
  position: relative;
  width: 100%;
  height: 4px;
  background-color: #dedede;
  margin-bottom: 20px;

  &_anchor {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    width: 100px;
    margin-left: -50px;
    background-color: #2f477f;
    cursor: row-resize;
  }
}

.viewForm {
  flex: 1;
  overflow: auto;
}

.formCategoryWrapper {
  margin-bottom: 20px;
}

.designContainer {
  flex: 1;
  overflow: auto;
}

.actionsContainer {
  display: flex;
  justify-content: flex-end;
  padding: 0 20px;
}

.actionsContainer>div {
  margin-left: 5px;
}

.activeAction svg path {
  fill: #1890ff;
}

.designContent {

}

.activeCell, .activeRow {
  background-color: rgba(135, 206, 235, 0.15);
}

.actionIcon {
  cursor: pointer;
}

@primary-color: #1DA57A;