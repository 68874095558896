@import 'styles/reset.css';

html,
body {
  padding: 0;
  margin: 0;
  height: 100%;
}

p {
  margin: 0;
}

ul,
li {
  padding: 0;
  margin: 0;
  list-style: none;
}

#root {
  height: 100%;
}

.App {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.app-body-container {
  flex: 1;
  height: 0;
}

.app-body-container {
  display: flex;
}

.app-content-wrapper {
  flex: 1;
  width: 0;
}

.oe-mui-link {
  color: rgb(205, 211, 223);
  cursor: pointer;
}

.oe-jsexec-div {
  display:block;
  height: 0;
  width: 0;
}

body.global-cursor-check * {
  cursor: url(./assets/imgs/check.png), auto !important;
}

body.global-cursor-cross * {
  cursor: url(./assets/imgs/cross.png), auto !important;
}

body.global-cursor-plus * {
  cursor: url(./assets/imgs/plus.png), auto !important;
}

body.global-cursor-copy * {
  cursor: copy !important;
}

body.global-cursor-move * {
  cursor: move !important;
}

body.global-cursor-not-allowed * {
  cursor: not-allowed !important;
}

.o-drag-container {
  height: 100%;
  position: relative;
}


