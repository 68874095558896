.InputWithUnit {
  position: relative;
  width: 202px;
}

.clearInput {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.25);
  position: absolute;
  right: 6px;
  top: 6px;
  border: 0;
  outline: 0;
  background-color: transparent;
  z-index: 100;

  &:hover {
    color: rgba(0, 0, 0, 0.45);
  }
}

.highlight {
  color: #1890ff;
}
@primary-color: #1DA57A;