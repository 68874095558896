/*
@directions:("t":"top", "b":"bottom", "l":"left", "r":"right");
@dimensions:("p":"padding", "m":"margin");

@each @dim in @dimensions {

  @each @dir in @directions {
    @for @i from 1 through 5 {
      @size: @i*5;
      .#{nth(@dim,1)}#{nth(@dir,1)}-#{@size} {
        #{nth(@dim,2)}-#{nth(@dir,2)}: #{@size}px;
      }
    }
  }
}

@for @i from 1 through 20 {
  @size: @i*5;
  .w-#{@size} {
    width: #{@size}px;
  }
}
*/
.btn-container {
  display: flex;
  justify-content: flex-end;
}
.primary-color {
  color: #1890ff;
}
.active-item {
  background-color: #1890ff;
  color: #fff;
}
.error-tips {
  color: red;
}
.highlighter-words {
  background-color: #ffd54f;
}
.ant-tree-list-holder {
  display: inline-block;
  min-width: 100%;
}
.ant-tree-node-content-wrapper {
  white-space: nowrap;
}
.ant-tabs-tab .ant-btn-group button {
  border: none;
  outline: none;
  background: none;
  box-shadow: none;
}
.anticon .iconfont {
  font-size: 14px;
}
.auto-menu-width {
  width: auto !important;
}
.common-dialog-title {
  display: flex;
  align-items: center;
  white-space: nowrap;
}
.common-dialog-title .common-dialog-title-icon {
  width: 24px;
  height: 24px;
  background-color: #eee;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  color: #333;
}
.common-dialog-title .common-dialog-title-icon > .anticon {
  margin: auto;
  font-size: 20px;
}
.common-dialog-title .common-dialog-title-icon > img {
  width: 20px;
  height: 20px;
}
.common-dialog-title .common-dialog-title-text {
  flex: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.table-cell-edit {
  outline-color: #666;
  width: 100%;
}
.select-no-arrow .ant-select-arrow {
  display: none !important;
}
.horizontal-form .form-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 10px;
}
.horizontal-form .form-item-label {
  text-align: right;
  padding-right: 5px;
}
.horizontal-form .buttons-container {
  margin-top: 30px;
}
.virtual-table .ant-table-container:before,
.virtual-table .ant-table-container:after {
  display: none;
}
.virtual-table-cell {
  box-sizing: border-box;
  padding: 16px;
  border-bottom: 1px solid #e8e8e8;
  border-right: 1px solid #e8e8e8;
  background: #FFF;
}
[data-theme="dark"] .virtual-table-cell {
  box-sizing: border-box;
  padding: 16px;
  border-bottom: 1px solid #303030;
  border-right: 1px solid #303030;
  background: #141414;
}
.ant-menu-orbit {
  color: rgba(255, 255, 255, 0.65);
}
.ant-menu-orbit:hover,
.ant-menu-orbit:visited {
  color: #fff;
}
.ant-menu-orbit .ant-menu-submenu-title:hover,
.ant-menu-orbit .ant-menu-submenu-arrow:hover,
.ant-menu-orbit .ant-menu-submenu-title:visited,
.ant-menu-orbit .ant-menu-submenu-arrow:visited {
  color: #fff;
}
.ant-menu-orbit .ant-menu-submenu-title {
  color: rgba(255, 255, 255, 0.65);
}
.ant-menu-orbit .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu-orbit .ant-menu-submenu-title .ant-menu-submenu-arrow::after {
  background: rgba(255, 255, 255, 0.65);
}
.ant-menu-orbit .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before,
.ant-menu-orbit .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after {
  background: #fff;
}
.ant-menu-orbit .ant-menu-sub > .ant-menu-submenu {
  background-color: #4561a1;
}
.ant-menu-orbit .ant-menu-submenu {
  background-color: #2f477f;
}
.ant-menu-orbit .ant-menu-submenu .ant-menu-sub {
  background-color: #fff;
}
.ant-menu-orbit .ant-menu-item {
  background-color: #fff;
}
.ant-menu-orbit .ant-menu-submenu-selected {
  color: #fff;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #1890ff !important;
  color: #fff;
}
.orbit-child-menu .ant-menu-submenu {
  background-color: #4561a1;
}
.ant-menu-item-disabled > .ant-menu-submenu-title,
.ant-menu-submenu-disabled > .ant-menu-submenu-title {
  color: rgba(255, 255, 255, 0.65) !important;
}
.ant-menu-item-disabled > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu-submenu-disabled > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu-item-disabled > .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu-submenu-disabled > .ant-menu-submenu-title .ant-menu-submenu-arrow::after {
  background: rgba(255, 255, 255, 0.65) !important;
}
.editor-toolbar {
  display: flex;
  align-items: center;
  background: transparent;
  border-top: 1px solid #e8e8e8;
  border-right: 1px solid #e8e8e8;
}
.editor-toolbar .icon-btn-group {
  padding: 4px 8px 3px;
  height: 39px;
  width: auto;
  border-left: 1px solid #e8e8e8;
}
.editor-toolbar .icon-btn {
  display: inline-block;
  height: 32px;
  width: auto;
  min-width: 32px;
  margin: 0;
  text-align: center;
  padding: 0 7px;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 3px 3px;
  font-size: 16px;
  cursor: pointer;
  color: #595959;
  outline: none;
  line-height: 32px;
}
.editor-toolbar .icon-btn:hover {
  border: 1px solid transparent;
  background-color: #f5f5f5;
}
.editor-toolbar .icon-btn-active {
  background-color: #e8e8e8;
  border: 1px solid transparent;
}
.editor-toolbar .popup-action {
  position: relative;
}
.editor-toolbar .popup-action .popup-container {
  position: absolute;
  left: 0;
}
.slate-editor-body {
  border: 1px solid #e8e8e8;
  min-height: 300px;
  padding: 5px 10px;
}
.slate-editor-body ul,
.slate-editor-body li {
  list-style: inherit;
}
.slate-editor-body ul,
.slate-editor-body ol {
  padding-inline-start: 40px;
}
.icon-strikethrough {
  font-size: 18px;
  font-weight: bold;
}
