.loginButtonWrapper {
  margin-top: 40px;
  margin-bottom: 10px;
}

.loginButton {
  width: 100%;
}

.textForgot {
  text-align: center;
}
@primary-color: #1DA57A;