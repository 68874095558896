.btn-container {
  display: flex;
  justify-content: flex-end;
}

.primary-color {
  color: #1890ff;
}

.active-item {
  background-color: #1890ff;
  color: #fff;
}

.error-tips {
  color: red;
}

.highlighter-words {
  background-color: #ffd54f;
}

.ant-tree-list-holder {
  display: inline-block;
  min-width: 100%;
}
.ant-tree-node-content-wrapper {
  white-space: nowrap;
}

.ant-tabs-tab {
  .ant-btn-group {
    button {
      border: none;
      outline: none;
      background: none;
      box-shadow: none;
    }
  }
}

.anticon .iconfont {
  font-size: 14px;
}

.auto-menu-width {
  width: auto !important;
}

.common-dialog-title {
  display: flex;
  align-items: center;
  white-space: nowrap;

  .common-dialog-title-icon {
    width: 24px;
    height: 24px;
    background-color: #eee;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    color: #333;

    & > .anticon {
      margin: auto;
      font-size: 20px;
    }

    & > img {
      width: 20px;
      height: 20px;
    }
  }

  .common-dialog-title-text {
    flex: auto;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.table-cell-edit {
  outline-color: #666;
  width: 100%;
}

.select-no-arrow {
  .ant-select-arrow {
    display: none !important;
  }
}
