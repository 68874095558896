@import '../../../styles/variableds';

.OrbitWindow {
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 50px;
  left: 50%;
  width: 400px;
  height: 300px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  z-index: 10000;
  pointer-events: auto;
  overflow: hidden;

  &_wrapper {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 10000;
    background-color: rgba(0, 0, 0, .3);
  }

  &_maximize {
    left: 0 !important;
    right: 0 !important;
    top: 0 !important;
    bottom: 0 !important;
    width: auto !important;
    height: auto !important;
  }

  &_hide {
    display: none;
  }

  &_toolbar {
    display: flex;
    align-items: center;
    height: @windowToolbarHeight;
    background-color: @windowToolbarBgColor;
    padding: 0 10px;
    border-bottom: 1px solid #CACACA;

    &_buttons {
      display: flex;
      align-items: center;
      height: 100%;
    }

    &_button {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: transparent;
      border: 1px solid #CACACA;
      outline: 0;
      border-radius: 2px;
      width: 30px;
      height: 30px;
      padding: 0;
      margin-left: 5px;
      cursor: pointer;

      &:hover {
        background-color: #cdd3df;
      }
    }
  }

  &_title {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-size: 24px;
    color: @text-color-black;
  }

  &_space {
    flex: 1;
    height: 100%;
  }

  &_content {
    padding: 15px;
    flex: 1;
    overflow: auto;
  }

  &_contentNoPadding {
    padding: 0px;
    flex: 1;
    overflow: auto;
  }

  &_footer {
    display: flex;
    align-items: center;
    padding: 10px;

    &_space {
      flex: 1;
    }

    &_submit_button {
      margin-left: 10px;
    }
  }
}

@primary-color: #1DA57A;