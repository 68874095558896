@import '../../../styles/variableds';

.bottomPane {
  display: flex;
  align-items: center;
  background-color: @bottomPaneBgColor;
  color: @text-color-normal;
  height: 36px;
  padding: 0 10px;
  box-shadow: 0px -1px 0px 0px #EAEDF3;

  &_sep {
    flex: 1;
  }

  &_link {
    color: @text-color-normal;
    cursor: pointer;
  }

  &_plink {
    color: @text-color-normal;
    cursor: pointer;
    margin: 0 5px;
  }
}

@primary-color: #1DA57A;