.viewForm_container {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.viewForm_preview {
  width: 100%;
  overflow: auto;
  margin-bottom: 20px;
}

.viewForm_dragHandle {
  position: relative;
  width: 100%;
  height: 4px;
  background-color: #dedede;
  margin-bottom: 20px;

  &_anchor {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    width: 100px;
    margin-left: -50px;
    background-color: #2f477f;
    cursor: row-resize;
  }
}

.viewForm {
  flex: 1;
  overflow: auto;
}

.formCategoryWrapper {
  margin-bottom: 20px;
}

.formSubHeader {
  font-size: 18px;
  font-weight: bold;
  color: #999;
  margin-bottom: 8px;
}

.formRow {
  margin-bottom: 10px;
  display: flex;
  align-items: center;

  .formLabel {
    margin-right: 8px;
    width: 140px;
  }
}

.ViewFormTableView_wrapper {
  position: relative;
}

.Columns_select_container {
  overflow: hidden;
  display: flex;
  position: absolute;
  width: 100%;
  background: #fff;
  z-index: 1;
}

.Columns_design_container {
  margin-top: 30px;
}

.Columns_select_item {
  margin-right: 10px;
}

.Columns_select_item_active {
  background-color: rgba(135, 206, 235, 0.3) !important;
}

@primary-color: #1DA57A;