.viewForm_container {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.ViewFormTableView {

}

.attributeContent:hover .close {
  display: flex;
}

.close {
  position: absolute;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  right: 0;
  top: 0;
  display: none;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 1px solid red;
  color: red;
  background-color: #fff;
}

@primary-color: #1DA57A;