@import '../../../styles/variableds';

.topNav {
  display: flex;
  align-items: center;
  background-color: #fff;
  color: #cdd3df;
  height: 40px;
  box-shadow: 0px 1px 0px 0px #EAEDF3;

  &_logo {
    font-size: 1.8em;
    color: #cdd3df;
    background-color: @sidebarBgColor;
    height: 100%;
    transition: all .5s cubic-bezier(.25,.1,.36,1);
    padding-left: 10px;

    svg {
      height: 100%;
      fill: #fff;
    }
  }

  &_menu_switch {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 20px;
    height: 20px;
    width: 20px;
    border-radius: 20px;
    cursor: pointer;

    svg {
      fill: @topPaneTextColor;
      width: 16px;
    }

    &_active {
      background-color: rgba(205, 211, 223, .5);
    }
  }

  &_network_switch {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 6px;
    height: 20px;
    width: 20px;
    border-radius: 20px;
    font-size: 20px;
    cursor: pointer;
    color: #1c1e5d;

    svg {
      width: 16px;
      fill: #cdd3df;
    }

    &_online {
      svg {
        fill: @topPaneIconActiveColor;
      }
    }
  }

  &_settings_button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 6px;
    height: 20px;
    width: 20px;
    border-radius: 20px;
    font-size: 20px;
    cursor: pointer;

    svg {
      fill: @topPaneTextColor;
      width: 100%;
      height: 100%;
    }

    svg {
      fill: @topPaneIconActiveColor;
    }
  }

  &_sep {
    flex: 1;
  }

  &_tools {
    margin-right: 30px;
    display: flex;
    align-items: center;

    &_link {
      color: @topPaneTextColor;
      cursor: pointer;
      margin: 0 5px;

      &:hover {
        color: @topPaneTextColor;
      }
    }
  }
}

.search {
  position: relative;

  &_icon {
    position: relative;
    left: -24px;
    font-size: 16px;
    cursor: pointer;
  }
}

@primary-color: #1DA57A;