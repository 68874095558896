@hoverColor: #fff;
@defaultColor: rgba(255, 255, 255, 0.65);

.ant-menu-orbit {
  color: @defaultColor;

  &:hover, &:visited {
    color: @hoverColor;
  }

  .ant-menu-submenu-title, .ant-menu-submenu-arrow {
    &:hover, &:visited {
      color: @hoverColor;
    }
  }

  .ant-menu-submenu-title {
    color: @defaultColor;

    .ant-menu-submenu-arrow::before, .ant-menu-submenu-arrow::after {
      background: @defaultColor;
    }

    &:hover {
      .ant-menu-submenu-arrow::before, .ant-menu-submenu-arrow::after {
        background: @hoverColor;
      }
    }
  }

  .ant-menu-sub > .ant-menu-submenu {
    background-color: #4561a1;
  }

  .ant-menu-submenu {
    background-color: #2f477f;
    .ant-menu-sub {
      background-color: #fff;
    }
  }

  .ant-menu-item {
    background-color: #fff;
  }

  .ant-menu-submenu-selected {
    color: #fff;
  }

}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #1890ff !important;
  color: #fff;
}

.orbit-child-menu {
  .ant-menu-submenu {
    background-color: #4561a1;
  }
}

.ant-menu-item-disabled > .ant-menu-submenu-title, .ant-menu-submenu-disabled > .ant-menu-submenu-title {
  color: @defaultColor !important;

  .ant-menu-submenu-arrow::before, .ant-menu-submenu-arrow::after {
    background: @defaultColor !important;
  }
}
