.odmtree_container {
    min-width: fit-content;
    background: repeating-linear-gradient( to bottom, #FFFFFF, #FFFFFF 0px, #E8E8E8 0px, #E8E8E8 32px, #FFFFFF 32px, #FFFFFF 64px );
}

.odmtree_container > li {
    padding-bottom: 3px !important;
}

.treeNodeIcon {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.treeNodeIcon svg, .treeNodeIcon img {
  width: 100%;
  height: 100%;
}

/**  None of this worked to set the cursor
.odmtreenode {
    &_nodrop:hover {
        cursor: no-drop !important;
        & span:hover {
            cursor: no-drop !important;
            & span:hover {
                cursor: no-drop !important;
                & a:hover {
                    cursor: no-drop !important;
                }
            }
        }
    }
}

.odmtree_container > li:hover {
  cursor: no-drop !important;
}

.odmtree_container > li > span:hover {
  cursor: no-drop !important;
}

.odmtree_container > li > span  > span:hover {
  cursor: no-drop !important;
}

.odmtree_container > li > span  > span > a:hover {
  cursor: no-drop !important;
}

**/

@primary-color: #1DA57A;